import {
    CREATE_3D_ROOM_START,
    CREATE_3D_ROOM_SUCCESS,
    CREATE_3D_ROOM_FAIL,

    CREATE_PUBLIC_3D_ROOM_FAIL,
    CREATE_PUBLIC_3D_ROOM_START,
    CREATE_PUBLIC_3D_ROOM_SUCCESS,

    GET_3D_ROOM_START,
    GET_3D_ROOM_SUCCESS,
    GET_3D_ROOM_FAIL,

    GET_PUBLIC_3D_ROOM_START,
    GET_PUBLIC_3D_ROOM_SUCCESS,
    GET_PUBLIC_3D_ROOM_FAIL,

    GET_3D_ROOMS_START,
    GET_3D_ROOMS_SUCCESS,
    GET_3D_ROOMS_FAIL,

    GET_3D_ROOMS_BY_USER_START,
    GET_3D_ROOMS_BY_USER_SUCCESS,
    GET_3D_ROOMS_BY_USER_FAIL,

    ADD_MODEL_TO_3D_ROOM_START,
    ADD_MODEL_TO_3D_ROOM_SUCCESS,
    ADD_MODEL_TO_3D_ROOM_FAIL,

    ADD_MODEL_TO_PUBLIC_3D_ROOM_START,
    ADD_MODEL_TO_PUBLIC_3D_ROOM_SUCCESS,
    ADD_MODEL_TO_PUBLIC_3D_ROOM_FAIL,


    UPDATE_MODEL_IN_3D_ROOM_START,
    UPDATE_MODEL_IN_3D_ROOM_SUCCESS,
    UPDATE_MODEL_IN_3D_ROOM_FAIL,

    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_START,
    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_SUCCESS,
    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_FAIL,

    REMOVE_MODEL_FROM_3D_ROOM_START,
    REMOVE_MODEL_FROM_3D_ROOM_SUCCESS,
    REMOVE_MODEL_FROM_3D_ROOM_FAIL,

    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_START,
    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_SUCCESS,
    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_FAIL,

    UPDATE_3D_ROOM_START,
    UPDATE_3D_ROOM_SUCCESS,
    UPDATE_3D_ROOM_FAIL,

    DELETE_3D_ROOM_START,
    DELETE_3D_ROOM_SUCCESS,
    DELETE_3D_ROOM_FAIL,

    CLEAR

} from './actions';

const initialState = {
    isLoading: false,
    error: null,
    room: null,
    rooms: []
};

export default function rooms(state = initialState, action) {
    switch (action.type) {

        case CREATE_3D_ROOM_START:
            return { ...state, isLoading: true };
        case CREATE_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
                rooms: [...state.rooms, action.payload]
            };
        case CREATE_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case CREATE_PUBLIC_3D_ROOM_START:
            return { ...state, isLoading: true };
        case CREATE_PUBLIC_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
                rooms: [...state.rooms, action.payload]
            };
        case CREATE_PUBLIC_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case GET_PUBLIC_3D_ROOM_START:
            return { ...state, isLoading: true };
        case GET_PUBLIC_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case GET_PUBLIC_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case GET_3D_ROOM_START:
            return { ...state, isLoading: true };
        case GET_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case GET_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case GET_3D_ROOMS_START:
            return { ...state, isLoading: true };
        case GET_3D_ROOMS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rooms: action.payload,
            };
        case GET_3D_ROOMS_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case GET_3D_ROOMS_BY_USER_START:
            return { ...state, isLoading: true };
        case GET_3D_ROOMS_BY_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rooms: action.payload,
            };
        case GET_3D_ROOMS_BY_USER_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case ADD_MODEL_TO_3D_ROOM_START:
            return { ...state, isLoading: true };
        case ADD_MODEL_TO_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case ADD_MODEL_TO_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case ADD_MODEL_TO_PUBLIC_3D_ROOM_START:
            return { ...state, isLoading: true };
        case ADD_MODEL_TO_PUBLIC_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case ADD_MODEL_TO_PUBLIC_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case UPDATE_MODEL_IN_PUBLIC_3D_ROOM_START:
            return { ...state, isLoading: true };
        case UPDATE_MODEL_IN_PUBLIC_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case UPDATE_MODEL_IN_PUBLIC_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        case UPDATE_MODEL_IN_3D_ROOM_START:
            return { ...state, isLoading: true };
        case UPDATE_MODEL_IN_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case UPDATE_MODEL_IN_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case REMOVE_MODEL_FROM_3D_ROOM_START:
            return { ...state, isLoading: true };
        case REMOVE_MODEL_FROM_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case REMOVE_MODEL_FROM_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_START:
            return { ...state, isLoading: true };
        case REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case UPDATE_3D_ROOM_START:
            return { ...state, isLoading: true };
        case UPDATE_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                room: action.payload,
            };
        case UPDATE_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case DELETE_3D_ROOM_START:
            return { ...state, isLoading: true };
        case DELETE_3D_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rooms: action.payload,
            };
        case DELETE_3D_ROOM_FAIL:
            return { ...state, isLoading: false, error: action.payload };

        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
