import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGOUT_SUCCESS,
  CLEAR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SMS_VERIFICATION_REQUEST_START,
  SMS_VERIFICATION_REQUEST_SUCCESS,
  SMS_VERIFICATION_REQUEST_FAIL,
  VERIFY_PHONE_NUMBER_START,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_FAIL,
  CREATE_SUBSCRIPTION_START,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  CHECK_PHONE_VERIFICATION_START,
  CHECK_PHONE_VERIFICATION_SUCCESS,
  CHECK_PHONE_VERIFICATION_FAIL,
  CHECK_PAYMENT_START,
  CHECK_PAYMENT_SUCCESS,
  CHECK_PAYMENT_FAIL,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  GET_API_KEY_START,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_FAIL,
  REQUEST_API_KEY_START,
  REQUEST_API_KEY_SUCCESS,
  REQUEST_API_KEY_FAIL,
  GET_COMPANY_DATA_START,
  GET_COMPANY_DATA_SUCCESS,
  GET_COMPANY_DATA_FAIL,
  USER_REGISTER_START,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  DEACTIVATE_USER_START,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
} from "./actions";

const initialState = {
  isFetching: false,
  user: {},
  isAuthenticated: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, isFetching: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload.body,
        isAuthenticated: true,
      };
    case LOGIN_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case SIGNUP_START:
      return { ...state, isFetching: true };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload.body,
        isAuthenticated: false,
      };
    case SIGNUP_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case LOGOUT_SUCCESS:
      return { ...state, isAuthenticated: false };
    case CLEAR:
      return initialState;
    case CHANGE_PASSWORD_START:
      return { ...state, isFetching: true };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case FORGOT_PASSWORD_START:
      return { ...state, isFetching: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FORGOT_PASSWORD_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case RESET_PASSWORD_START:
      return { ...state, isFetching: true };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_PASSWORD_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case SMS_VERIFICATION_REQUEST_START:
      return { ...state, isFetching: true };
    case SMS_VERIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SMS_VERIFICATION_REQUEST_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case VERIFY_PHONE_NUMBER_START:
      return { ...state, isFetching: true };
    case VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case VERIFY_PHONE_NUMBER_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case CREATE_SUBSCRIPTION_START:
      return { ...state, isFetching: true };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_SUBSCRIPTION_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case CHECK_PHONE_VERIFICATION_START:
      return { ...state, isFetching: true };
    case CHECK_PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CHECK_PHONE_VERIFICATION_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case CHECK_PAYMENT_START:
      return { ...state, isFetching: true };
    case CHECK_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CHECK_PAYMENT_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case DELETE_ACCOUNT_START:
      return { ...state, isFetching: true };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_ACCOUNT_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case CANCEL_SUBSCRIPTION_START:
      return { ...state, isFetching: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CANCEL_SUBSCRIPTION_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };
    case GET_API_KEY_START:
      return { ...state, isFetching: true };
    case GET_API_KEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_API_KEY_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case REQUEST_API_KEY_START:
      return { ...state, isFetching: true };
    case REQUEST_API_KEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_API_KEY_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case GET_COMPANY_DATA_START:
      return { ...state, isFetching: true };
    case GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_COMPANY_DATA_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case USER_REGISTER_START:
      return { ...state, isFetching: true };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case USER_REGISTER_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    case DEACTIVATE_USER_START:
      return { ...state, isFetching: true };
    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case DEACTIVATE_USER_FAIL:
      return { ...state, isFetching: false, ...action.payload.error };

    
    default:
      return state;
  }
}
