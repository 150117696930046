import { getFileExtension } from "../utils";
import * as Yup from 'yup';



export const SignupSchema = Yup.object({
  userName: Yup.string().required('Username cannot be empty').min(3, "Username must be at least 3 chracters"),
  email: Yup.string().email("Email must be valid !").max(255).required('Email cannot be empty'),
  companyName: Yup.string().required('Please enter your company name').min(3, "Company name must be at least 3 characters"),
  password: Yup.string().min(8, 'Password must be at least 8 characters').matches(/(?=.*[0-9])/, "Password must contain a number.").required('Password cannot be empty'),
  confirmPassword: Yup.string().min(8, 'Confirmation password must be at least 8 characters').matches(/(?=.*[0-9])/, "Password must contain a number.").required('Confirmation password cannot be empty')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export const LoginSchema = Yup.object({
  email: Yup.string().email().max(255).required('Email cannot be empty'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').matches(/(?=.*[0-9])/, "Password must contain a number.").required('Password cannot be empty')
})
export function isEmail(emailAddress) {
  return /.+@.+\..{2,}/i.test(emailAddress);
}

// args file: File | string
export function isImageFile(file) {
  const allowedExtensions = [
    ".jpg",
    ".png",
    ".jpeg",
    ".bmp",
    ".gif",
    ".tga",
    ".targa",
    ".tiff"
  ];
  if (file instanceof File) {
    return (
      file &&
      (file.type.split("/")[0] === "image" ||
        allowedExtensions.indexOf(getFileExtension(file.name) !== -1))
    );
  } else if (file instanceof String || typeof file === "string") {
    return allowedExtensions.includes(getFileExtension(file));
  }
}

// arg file: File
// typically in a FileList object from an html file input element
export function isZip(
  file,
  zipMimeTypes = ["application/zip", "application/x-zip-compressed"],
  zipExtensions = [".zip"]
) {
  return (
    zipMimeTypes.includes(file.type) ||
    zipExtensions.includes(getFileExtension(file.name))
  );
}
