export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // TODO if remove Macintosh not working on tablet if not on imac it says iOS and not model viewer

/*
const ua = window.navigator.userAgent.toLowerCase();

const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

const isiPad = ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

// iPhone
const isiPhone = (ua.indexOf('iphone') > -1);
​
// Android
const isAndroid = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') > -1);
​
// Android Tablet
const isAndroidTablet = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') === -1);
*/

export function supportp(feature) {
  if (feature === "ar") {
    const a = document.createElement("a");
    if (a.relList.supports("ar")) {
      // AR is available.
      return true;
    }
  } else {
    return false;
  }
}

export const supportsAnchorRelAR = () =>
  document.createElement("a").relList.supports("ar");
