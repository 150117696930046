import { useEffect } from "react";
import { gsap } from "gsap";

export const useAnimations = (ref, isShowing) => {
  useEffect(() => {
    if (ref) {
      gsap.fromTo(
        ref,
        {
          opacity: isShowing ? 0 : 1,
        },
        {
          opacity: isShowing ? 1 : 0,
          display: isShowing ? "block" : "none",
          duration: 1,
          stagger: 0.2,
        }
      );
    }
  }, [ref, isShowing]);
};
