/* ACTIONS */
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CLEAR = "CLEAR";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const SMS_VERIFICATION_REQUEST_START = "SMS_VERIFICATION_REQUEST_START";
export const SMS_VERIFICATION_REQUEST_SUCCESS =
  "SMS_VERIFICATION_REQUEST_SUCCESS";
export const SMS_VERIFICATION_REQUEST_FAIL = "SMS_VERIFICATION_REQUEST_FAIL";

export const VERIFY_PHONE_NUMBER_START = "VERIFY_PHONE_NUMBER_START";
export const VERIFY_PHONE_NUMBER_SUCCESS = "VERIFY_PHONE_NUMBER_SUCCESS";
export const VERIFY_PHONE_NUMBER_FAIL = "VERIFY_PHONE_NUMBER_FAIL";

export const CREATE_SUBSCRIPTION_START = "CREATE_SUBSCRIPTION_START";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";

export const CHECK_PHONE_VERIFICATION_START = "CHECK_PHONE_VERIFICATION_START";
export const CHECK_PHONE_VERIFICATION_SUCCESS =
  "CHECK_PHONE_VERIFICATION_SUCCESS";
export const CHECK_PHONE_VERIFICATION_FAIL = "CHECK_PHONE_VERIFICATION_FAIL";

export const CHECK_PAYMENT_START = "CHECK_PAYMENT_START";
export const CHECK_PAYMENT_SUCCESS = "CHECK_PAYMENT_SUCCESS";
export const CHECK_PAYMENT_FAIL = "CHECK_PAYMENT_FAIL";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

export const CANCEL_SUBSCRIPTION_START = "CANCEL_SUBSCRIPTION_START";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";

export const GET_API_KEY_START = "GET_API_KEY_START";
export const GET_API_KEY_SUCCESS = "GET_API_KEY_SUCCESS";
export const GET_API_KEY_FAIL = "GET_API_KEY_FAIL";

export const REQUEST_API_KEY_START = "REQUEST_API_KEY_START";
export const REQUEST_API_KEY_SUCCESS = "REQUEST_API_KEY_SUCCESS";
export const REQUEST_API_KEY_FAIL = "REQUEST_API_KEY_FAIL";

export const GET_COMPANY_DATA_START = "GET_COMPANY_DATA_START";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL";

export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";


export const DEACTIVATE_USER_START = "DEACTIVATE_USER_START";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_FAIL = "DEACTIVATE_USER_FAIL";

/* end ACTIONS */
