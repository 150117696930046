import {
  GET_SCENES_START,
  GET_SCENES_SUCCESS,
  GET_SCENES_FAIL,
  POST_SCENE_START,
  POST_SCENE_SUCCESS,
  POST_SCENE_FAIL,
  GET_SCENE_START,
  GET_SCENE_SUCCESS,
  GET_SCENE_FAIL,
  DELETE_SCENE_START,
  DELETE_SCENE_SUCCESS,
  DELETE_SCENE_FAIL,
  UPLOAD_THUMBNAIL_START,
  UPLOAD_THUMBNAIL_SUCCESS,
  UPLOAD_THUMBNAIL_FAIL,
  CLEAR,
  UPDATE_SCENE_START,
  UPDATE_SCENE_FAIL,
  UPDATE_SCENE_SUCCESS,
  TOGGLE_SCENE_SHARE_START,
  TOGGLE_SCENE_SHARE_SUCCESS,
  TOGGLE_SCENE_SHARE_FAIL,
  GET_PUBLIC_SCENES_START,
  GET_PUBLIC_SCENES_SUCCESS,
  GET_PUBLIC_SCENES_FAIL,
  UPLOAD_SCENE_START,
  UPLOAD_SCENE_SUCCESS,
  UPLOAD_SCENE_FAIL,
  SEARCH_SCENES_START,
  SEARCH_SCENES_SUCCESS,
  SEARCH_SCENES_FAIL,
  SEARCH_PUBLIC_SCENES_START,
  SEARCH_PUBLIC_SCENES_SUCCESS,
  SEARCH_PUBLIC_SCENES_FAIL,
  ADD_PRODUCT_MODEL_URL_START,
  ADD_PRODUCT_MODEL_URL_SUCCESS,
  ADD_PRODUCT_MODEL_URL_FAIL,
} from "./actions";
import { getFileExtension } from "../../utils";

const initialState = {
  isFetching: false,
  items: [],
  error: {},
  itemCount: 0,
  publicItems: [],
  publicItemCount: 0,
};
export default function scenes(state = initialState, action) {
  switch (action.type) {
    case GET_SCENES_START:
      return { ...state, isFetching: true };
    case GET_SCENES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload.body,
        itemCount: action.payload.count,
      };
    case GET_SCENES_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case POST_SCENE_START:
      return { ...state, isFetching: true };
    case POST_SCENE_SUCCESS:
      return { ...state, isFetching: false };
    case POST_SCENE_FAIL:
      return { ...state, isFetching: false };
    case GET_SCENE_START:
      return { ...state, isFetching: true };
    case GET_SCENE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [
          ...state.items.filter((i) => i._id !== action.payload.body.scene._id),
          action.payload.body.scene,
        ],
      };
    case GET_SCENE_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case DELETE_SCENE_START:
      return { ...state, isFetching: true };
    case DELETE_SCENE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [
          ...state.items.filter((i) => i._id !== action.payload.body.scene._id),
        ],
      };
    case DELETE_SCENE_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case UPLOAD_THUMBNAIL_START:
      return { ...state, isFetching: true };
    case UPLOAD_THUMBNAIL_SUCCESS:
      return { ...state, isFetching: false };
    case UPLOAD_THUMBNAIL_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case CLEAR:
      return initialState;
    case UPDATE_SCENE_START:
      return { ...state, isFetching: true };
    case UPDATE_SCENE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [
          ...state.items.filter((i) => i._id !== action.payload.body.scene._id),
          action.payload.body.scene,
        ],
      };
    case UPDATE_SCENE_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case TOGGLE_SCENE_SHARE_START:
      return { ...state, isFetching: true };
    case TOGGLE_SCENE_SHARE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [
          ...state.items.map((item) =>
            item._id === action.payload.body.scene._id
              ? action.payload.body.scene
              : item
          ),
        ],
      };
    case TOGGLE_SCENE_SHARE_FAIL:
      return { ...state, isFetching: false };
    case GET_PUBLIC_SCENES_START:
      return { ...state, isFetching: true };
    case GET_PUBLIC_SCENES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        publicItems: action.payload.body,
        publicItemCount: action.payload.count,
      };
    case GET_PUBLIC_SCENES_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case UPLOAD_SCENE_START:
      return { ...state, isFetching: true };
    case UPLOAD_SCENE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [
          ...state.items.map((s) =>
            s._id === action.payload.sceneId
              ? {
                  ...s,
                  files: [
                    ...s.files.map((f) =>
                      getFileExtension(f.fileName) === ".usdz"
                        ? { fileName: action.payload.fileName }
                        : f
                    ),
                  ],
                } // Ideally endpoint should return a representation of new source, hence RESTful
              : s
          ),
        ],
      };
    case UPLOAD_SCENE_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case SEARCH_SCENES_START:
      return { ...state, isFetching: true };
    case SEARCH_SCENES_SUCCESS: {
      let newItems = [];
      if (state.items.filter((item) => item.name === "Temporary Scene")[0]) {
        newItems = [
          ...action.payload.body,
          state.items.filter((item) => item.name === "Temporary Scene")[0],
        ];
      } else {
        newItems = action.payload.body;
      }
      return {
        ...state,
        isFetching: false,
        items: newItems,
        itemCount: action.payload.count,
      };
    }
    case SEARCH_SCENES_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };
    case SEARCH_PUBLIC_SCENES_START:
      return { ...state, isFetching: true };
    case SEARCH_PUBLIC_SCENES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        publicItems: action.payload.body,
        publicItemCount: action.payload.count,
      };
    case SEARCH_PUBLIC_SCENES_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };

    case ADD_PRODUCT_MODEL_URL_START:
      return { ...state, isFetching: true };
    case ADD_PRODUCT_MODEL_URL_SUCCESS:
      return { ...state, isFetching: false };
    case ADD_PRODUCT_MODEL_URL_FAIL:
      return { ...state, isFetching: false, error: action.payload.error };

    default:
      return state;
  }
}
