import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { IonApp, IonButton } from '@ionic/react';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { PrivateRoute } from './utils/routing';
import './theme.css';
import Loading from './components/Loading';
import { ToastContainer } from 'react-toastify';
const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ConfirmMailAddress = lazy(() => import('./pages/ConfirmMailAddress'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const AppStack = lazy(() => import('./pages/AppStack'));
const CheckPayment = lazy(() => import('./pages/CheckPayment'));
const LoginNew = lazy(() => import('./pages/LoginNew'));
const SignupNew = lazy(() => import('./pages/SignupNew'));
const RoomCustomizeDemo = lazy(() => import('./pages/RoomCustomizeDemo'));
// Export this for store functions to import
// export const remote_url = "https://holonext-ar-api.azurewebsites.net/api";

// remove console log in production mode (for security)
if (process.env.NODE_ENV === 'production') {
	console.log = () => { }
}



export const remote_url = '/api/v1';

const AcceptCookies = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background: #3880ff;
	display: flex;
	text-align: center;
	align-items: center;
	padding: 0 20px;
	color: white;
	justify-content: space-between;
	z-index: 9;
	@media (max-width: 550px) {
		height: unset;
		flex-direction: column;
	}
`;
const Text = styled.div`
	@media (max-width: 550px) {
		margin-right: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
`;

function App({ store }) {
	const [cookiesAccepted, setCookiesAccepted] = useState(false);
	return (
		<Provider store={store}>
			<Router>
				<IonApp>
					<Suspense fallback={<Loading show={true} />}>
						<Switch>
							<Route path='/login-old' exact component={Login} />
							<Route path='/signup-old' exact component={Signup} />
							<Route path='/forgotpassword' component={ForgotPassword} />
							<Route
								path='/confirmMailAddress/:hash'
								component={ConfirmMailAddress}
							/>
							<Route path='/login' component={LoginNew} />
							<Route path='/signup' component={SignupNew} />
							<Route path='/resetpassword/:hash' component={ResetPassword} />
							<Route path='/subscribeEnd' component={CheckPayment} />
							<Route path='/demo-room/:id?' component={RoomCustomizeDemo} />
							<PrivateRoute path='*' component={AppStack} />
						</Switch>
					</Suspense>
					<ToastContainer
						position='top-center'
						autoClose={2000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
					/>
					<Loading />

					{!store.getState().view.cookiesAccepted && !cookiesAccepted && (
						<AcceptCookies>
							<Text>
								This website uses cookies and your IP address to deliver and improve
								our web content, resolve technical errors, provide you with a
								personalized experience and collect analytics data.
							</Text>
							<IonButton
								color='success'
								onClick={() => {
									store.dispatch({ type: 'ACCEPT_COOKIES' });
									setCookiesAccepted(true);
								}}
							>
								I understand
							</IonButton>
						</AcceptCookies>
					)}
				</IonApp>
			</Router>
		</Provider>
	);
}

export default App;
