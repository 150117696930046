const CREATE_3D_ROOM_START = 'CREATE_3D_ROOM_START';
const CREATE_3D_ROOM_SUCCESS = 'CREATE_3D_ROOM_SUCCESS';
const CREATE_3D_ROOM_FAIL = 'CREATE_3D_ROOM_FAIL';

const CREATE_PUBLIC_3D_ROOM_START = 'CREATE_PUBLIC_3D_ROOM_START';
const CREATE_PUBLIC_3D_ROOM_SUCCESS = 'CREATE_PUBLIC_3D_ROOM_SUCCESS';
const CREATE_PUBLIC_3D_ROOM_FAIL = 'CREATE_PUBLIC_3D_ROOM_FAIL';

const GET_3D_ROOM_START = 'GET_3D_ROOM_START';
const GET_3D_ROOM_SUCCESS = 'GET_3D_ROOM_SUCCESS';
const GET_3D_ROOM_FAIL = 'GET_3D_ROOM_FAIL';

const GET_PUBLIC_3D_ROOM_START = 'GET_PUBLIC_3D_ROOMS_START';
const GET_PUBLIC_3D_ROOM_SUCCESS = 'GET_PUBLIC_3D_ROOMS_SUCCESS';
const GET_PUBLIC_3D_ROOM_FAIL = 'GET_PUBLIC_3D_ROOMS_FAIL';

const GET_3D_ROOMS_START = 'GET_3D_ROOMS_START';
const GET_3D_ROOMS_SUCCESS = 'GET_3D_ROOMS_SUCCESS';
const GET_3D_ROOMS_FAIL = 'GET_3D_ROOMS_FAIL';

const GET_3D_ROOMS_BY_USER_START = 'GET_3D_ROOMS_BY_USER_START';
const GET_3D_ROOMS_BY_USER_SUCCESS = 'GET_3D_ROOMS_BY_USER_SUCCESS';
const GET_3D_ROOMS_BY_USER_FAIL = 'GET_3D_ROOMS_BY_USER_FAIL';


const ADD_MODEL_TO_3D_ROOM_START = 'ADD_MODEL_TO_3D_ROOM_START';
const ADD_MODEL_TO_3D_ROOM_SUCCESS = 'ADD_MODEL_TO_3D_ROOM_SUCCESS';
const ADD_MODEL_TO_3D_ROOM_FAIL = 'ADD_MODEL_TO_3D_ROOM_FAIL';

const ADD_MODEL_TO_PUBLIC_3D_ROOM_START = 'ADD_MODEL_TO_PUBLIC_3D_ROOM_START';
const ADD_MODEL_TO_PUBLIC_3D_ROOM_SUCCESS = 'ADD_MODEL_TO_PUBLIC_3D_ROOM_SUCCESS';
const ADD_MODEL_TO_PUBLIC_3D_ROOM_FAIL = 'ADD_MODEL_TO_PUBLIC_3D_ROOM_FAIL';

const UPDATE_MODEL_IN_3D_ROOM_START = 'UPDATE_MODEL_IN_3D_ROOM_START';
const UPDATE_MODEL_IN_3D_ROOM_SUCCESS = 'UPDATE_MODEL_IN_3D_ROOM_SUCCESS';
const UPDATE_MODEL_IN_3D_ROOM_FAIL = 'UPDATE_MODEL_IN_3D_ROOM_FAIL';

const UPDATE_MODEL_IN_PUBLIC_3D_ROOM_START = 'UPDATE_MODEL_IN_PUBLIC_3D_ROOM_START';
const UPDATE_MODEL_IN_PUBLIC_3D_ROOM_SUCCESS = 'UPDATE_MODEL_IN_PUBLIC_3D_ROOM_SUCCESS';
const UPDATE_MODEL_IN_PUBLIC_3D_ROOM_FAIL = 'UPDATE_MODEL_IN_PUBLIC_3D_ROOM_FAIL';

const REMOVE_MODEL_FROM_3D_ROOM_START = 'REMOVE_MODEL_FROM_3D_ROOM_START';
const REMOVE_MODEL_FROM_3D_ROOM_SUCCESS = 'REMOVE_MODEL_FROM_3D_ROOM_SUCCESS';
const REMOVE_MODEL_FROM_3D_ROOM_FAIL = 'REMOVE_MODEL_FROM_3D_ROOM_FAIL';

const REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_START = 'REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_START';
const REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_SUCCESS = 'REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_SUCCESS';
const REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_FAIL = 'REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_FAIL';

const UPDATE_3D_ROOM_START = 'UPDATE_3D_ROOM_START';
const UPDATE_3D_ROOM_SUCCESS = 'UPDATE_3D_ROOM_SUCCESS';
const UPDATE_3D_ROOM_FAIL = 'UPDATE_3D_ROOM_FAIL';

const DELETE_3D_ROOM_START = 'DELETE_3D_ROOM_START';
const DELETE_3D_ROOM_SUCCESS = 'DELETE_3D_ROOM_SUCCESS';
const DELETE_3D_ROOM_FAIL = 'DELETE_3D_ROOM_FAIL';

const CLEAR = 'CLEAR';

export {
    CREATE_3D_ROOM_START,
    CREATE_3D_ROOM_SUCCESS,
    CREATE_3D_ROOM_FAIL,

    CREATE_PUBLIC_3D_ROOM_START,
    CREATE_PUBLIC_3D_ROOM_SUCCESS,
    CREATE_PUBLIC_3D_ROOM_FAIL,

    GET_3D_ROOM_START,
    GET_3D_ROOM_SUCCESS,
    GET_3D_ROOM_FAIL,

    GET_3D_ROOMS_START,
    GET_3D_ROOMS_SUCCESS,
    GET_3D_ROOMS_FAIL,

    GET_PUBLIC_3D_ROOM_START,
    GET_PUBLIC_3D_ROOM_SUCCESS,
    GET_PUBLIC_3D_ROOM_FAIL,

    GET_3D_ROOMS_BY_USER_START,
    GET_3D_ROOMS_BY_USER_SUCCESS,
    GET_3D_ROOMS_BY_USER_FAIL,

    ADD_MODEL_TO_3D_ROOM_START,
    ADD_MODEL_TO_3D_ROOM_SUCCESS,
    ADD_MODEL_TO_3D_ROOM_FAIL,

    ADD_MODEL_TO_PUBLIC_3D_ROOM_START,
    ADD_MODEL_TO_PUBLIC_3D_ROOM_SUCCESS,
    ADD_MODEL_TO_PUBLIC_3D_ROOM_FAIL,

    UPDATE_MODEL_IN_3D_ROOM_START,
    UPDATE_MODEL_IN_3D_ROOM_SUCCESS,
    UPDATE_MODEL_IN_3D_ROOM_FAIL,

    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_START,
    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_SUCCESS,
    UPDATE_MODEL_IN_PUBLIC_3D_ROOM_FAIL,

    REMOVE_MODEL_FROM_3D_ROOM_START,
    REMOVE_MODEL_FROM_3D_ROOM_SUCCESS,
    REMOVE_MODEL_FROM_3D_ROOM_FAIL,

    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_START,
    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_SUCCESS,
    REMOVE_MODEL_FROM_PUBLIC_3D_ROOM_FAIL,

    UPDATE_3D_ROOM_START,
    UPDATE_3D_ROOM_SUCCESS,
    UPDATE_3D_ROOM_FAIL,

    DELETE_3D_ROOM_START,
    DELETE_3D_ROOM_SUCCESS,
    DELETE_3D_ROOM_FAIL,

    CLEAR
}