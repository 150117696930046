/* ACTIONS */
export const GET_SCENES_START = "GET_SCENES_START";
export const GET_SCENES_SUCCESS = "GET_SCENES_SUCCESS";
export const GET_SCENES_FAIL = "GET_SCENES_FAIL";

export const POST_SCENE_START = "POST_SCENE_START";
export const POST_SCENE_SUCCESS = "POST_SCENE_SUCCESS";
export const POST_SCENE_FAIL = "POST_SCENE_FAIL";

export const GET_SCENE_START = "GET_SCENE_START";
export const GET_SCENE_SUCCESS = "GET_SCENE_SUCCESS";
export const GET_SCENE_FAIL = "GET_SCENE_FAIL";

export const DELETE_SCENE_START = "DELETE_SCENE_START";
export const DELETE_SCENE_SUCCESS = "DELETE_SCENE_SUCCESS";
export const DELETE_SCENE_FAIL = "DELETE_SCENE_FAIL";

export const UPLOAD_THUMBNAIL_START = "UPLOAD_THUMBNAIL_START";
export const UPLOAD_THUMBNAIL_SUCCESS = "UPLOAD_THUMBNAIL_SUCCESS";
export const UPLOAD_THUMBNAIL_FAIL = "UPLOAD_THUMBNAIL_FAIL";

export const CLEAR = "CLEAR";

export const UPDATE_SCENE_START = "UPDATE_SCENE_START";
export const UPDATE_SCENE_FAIL = "UPDATE_SCENE_FAIL";
export const UPDATE_SCENE_SUCCESS = "UPDATE_SCENE_SUCCESS";

export const TOGGLE_SCENE_SHARE_START = "TOGGLE_SCENE_SHARE_START";
export const TOGGLE_SCENE_SHARE_SUCCESS = "TOGGLE_SCENE_SHARE_SUCCESS";
export const TOGGLE_SCENE_SHARE_FAIL = "TOGGLE_SCENE_SHARE_FAIL";

export const GET_PUBLIC_SCENES_START = "GET_PUBLIC_SCENES_START";
export const GET_PUBLIC_SCENES_SUCCESS = "GET_PUBLIC_SCENES_SUCCESS";
export const GET_PUBLIC_SCENES_FAIL = "GET_PUBLIC_SCENES_FAIL";

export const UPLOAD_SCENE_START = "UPLOAD_SCENE_START";
export const UPLOAD_SCENE_SUCCESS = "UPLOAD_SCENE_SUCCESS";
export const UPLOAD_SCENE_FAIL = "UPLOAD_SCENE_FAIL";

export const SEARCH_SCENES_START = "SEARCH_SCENES_START";
export const SEARCH_SCENES_SUCCESS = "SEARCH_SCENES_SUCCESS";
export const SEARCH_SCENES_FAIL = "SEARCH_SCENES_FAIL";

export const SEARCH_PUBLIC_SCENES_START = "SEARCH_PUBLIC_SCENES_START";
export const SEARCH_PUBLIC_SCENES_SUCCESS = "SEARCH_PUBLIC_SCENES_SUCCESS";
export const SEARCH_PUBLIC_SCENES_FAIL = "SEARCH_PUBLIC_SCENES_FAIL";

export const ADD_PRODUCT_MODEL_URL_START = "ADD_PRODUCT_MODEL_URL_START";
export const ADD_PRODUCT_MODEL_URL_SUCCESS = "ADD_PRODUCT_MODEL_URL_SUCCESS";
export const ADD_PRODUCT_MODEL_URL_FAIL = "ADD_PRODUCT_MODEL_URL_FAIL";

export const GET_PRODUCT_MODEL_URLS_START = "GET_PRODUCT_MODEL_URLS_START";
export const GET_PRODUCT_MODEL_URLS_SUCCESS = "GET_PRODUCT_MODEL_URLS_SUCCESS";
export const GET_PRODUCT_MODEL_URLS_FAIL = "GET_PRODUCT_MODEL_URLS_FAIL";

export const UPLOAD_SOUND_START = "UPLOAD_SOUND_START";
export const UPLOAD_SOUND_SUCCESS = "UPLOAD_SOUND_SUCCESS";
export const UPLOAD_SOUND_FAIL = "UPLOAD_SOUND_FAIL";


export const GET_SCENE_MAKER_URL_START = "GET_SCENE_MAKER_URL_START";
export const GET_SCENE_MAKER_URL_SUCCESS = "GET_SCENE_MAKER_URL_SUCCESS";
export const GET_SCENE_MAKER_URL_FAIL = "GET_SCENE_MAKER_URL_FAIL";

export const UPLOAD_SCENE_MAKER_FILE_START = "UPLOAD_SCENE_MAKER_FILE_START";
export const UPLOAD_SCENE_MAKER_FILE_SUCCESS = "UPLOAD_SCENE_MAKER_FILE_SUCCESS";
export const UPLOAD_SCENE_MAKER_FILE_FAIL = "UPLOAD_SCENE_MAKER_FILE_FAIL";


export const  UPLOAD_SCENE_MARKER_IMAGE_START = "UPLOAD_SCENE_MARKER_IMAGE_START";
export const  UPLOAD_SCENE_MARKER_IMAGE_SUCCESS = "UPLOAD_SCENE_MARKER_IMAGE_SUCCESS";
export const  UPLOAD_SCENE_MARKER_IMAGE_FAIL = "UPLOAD_SCENE_MARKER_IMAGE_FAIL";


/* end ACTIONS */
