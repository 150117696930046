import { getFileName } from "./file";

// returns BJS Texture object
async function getTexture({ url, textureSingleton, scene, user }) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { Auth: `Bearer ${user.token}` }
    });
    const responseBlob = await response.blob();
    console.log("url: ", url);
    const fileName = getFileName({ path: url });
    return textureSingleton.LoadFromDataString(fileName, responseBlob, scene); // https://doc.babylonjs.com/api/classes/babylon.texture#loadfromdatastring
  } catch (err) {
    console.error("loadTexture: ", err);
  }
}
// end .OBJ LOAD TEXTURE

async function addTextures({ scene, textureSingleton, user }) {
  try {
    scene.executeWhenReady(function() {
      // 1. find all textures to download: traverse all meshes and their materials and textures downloaded scene
      // 2. download them, look at url
      // 3. set each texture to the correct material's correct texture property, usually diffuseTexture

      const textureKeys = [
        "diffuseTexture",
        "specularTexture",
        "ambientTexture",
        "emissiveTexture"
      ];

      scene.materials.forEach(mt => {
        textureKeys.forEach(async txkey => {
          if (mt[txkey] && mt[txkey].url) {
            mt[txkey] = await getTexture({
              url: mt[txkey].url,
              textureSingleton,
              scene,
              user
            });
          }
        });
      });
      /*
      engine.runRenderLoop(function() {
        scene.render();
      });
      */
    });
  } catch (err) {
    console.error(err);
  }
}

export { getTexture, addTextures };
