/* ACTIONS */
export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';

export const GET_MODEL_DATA_START = 'GET_MODEL_DATA_START';
export const GET_MODEL_DATA_SUCCESS = 'GET_MODEL_DATA_SUCCESS';
export const GET_MODEL_DATA_FAIL = 'GET_MODEL_DATA_FAIL';

export const SEND_FEEDBACK_START = 'SEND_FEEDBACK_START';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAIL = 'SEND_FEEDBACK_FAIL';

export const GET_VIEW_STATS_START = 'GET_VIEW_STATS_START';
export const GET_VIEW_STATS_SUCCESS = 'GET_VIEW_STATS_SUCCESS';
export const GET_VIEW_STATS_FAIL = 'GET_VIEW_STATS_FAIL';

export const CLEAR = 'CLEAR';

/* end ACTIONS */
