import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import { fetchScenes } from "./scenes/actions";
import { saveState, loadState } from "./localStorage";

import rootReducer from "./rootReducer";

const persistedState = loadState();

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
