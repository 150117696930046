import {
	GET_DASHBOARD_DATA_START,
	GET_DASHBOARD_DATA_SUCCESS,
	GET_DASHBOARD_DATA_FAIL,
	GET_MODEL_DATA_START,
	GET_MODEL_DATA_SUCCESS,
	GET_MODEL_DATA_FAIL,
	SEND_FEEDBACK_START,
	SEND_FEEDBACK_SUCCESS,
	SEND_FEEDBACK_FAIL,
	GET_VIEW_STATS_START,
	GET_VIEW_STATS_SUCCESS,
	GET_VIEW_STATS_FAIL,
	CLEAR,
} from './actions';

const initialState = {
	isFetching: false,
	data: {},
	modelsData: {},
	viewData: {},
};

export default function dashboard(state = initialState, action) {
	switch (action.type) {
		case GET_DASHBOARD_DATA_START:
			return { ...state, isFetching: true };
		case GET_DASHBOARD_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				data: action.payload.body,
			};
		case GET_DASHBOARD_DATA_FAIL:
			return { ...state, isFetching: false, error: action.payload.error };
		case GET_MODEL_DATA_START:
			return { ...state, isFetching: true };
		case GET_MODEL_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				modelsData: action.payload.body,
			};
		case GET_MODEL_DATA_FAIL:
			return { ...state, isFetching: false, error: action.payload.error };

		case SEND_FEEDBACK_START:
			return { ...state, isFetching: true };
		case SEND_FEEDBACK_SUCCESS:
			return {
				...state,
				isFetching: false,
			};
		case SEND_FEEDBACK_FAIL:
			return { ...state, isFetching: false, error: action.payload.error };

		case GET_VIEW_STATS_START:
			return { ...state, isFetching: true };
		case GET_VIEW_STATS_SUCCESS:
			return {
				...state,
				isFetching: false,
				viewData: action.payload.body,
			};
		case GET_VIEW_STATS_FAIL:
			return { ...state, isFetching: false, error: action.payload.error };
		case CLEAR:
			return initialState;
		default:
			return state;
	}
}
