import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// TODO: PropTypes
// component React.ComponentType
// path? string

class PRoute extends Component {
  render() {
    const Component = this.props.component;
    const routeRender = props => {
      if (this.props.user.isAuthenticated) {
        return React.createElement(Component, props);
      }
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export const PrivateRoute = connect(mapStateToProps)(PRoute);
