import { ACCEPT_COOKIES, SHOW_LOADING, HIDE_LOADING } from "./actions";

const initialState = {
  loading: false,
  cookiesAccepted: false
};

export default function view(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_COOKIES:
      return { ...state, cookiesAccepted: true };
    case SHOW_LOADING:
      return { ...state, loading: true };
    case HIDE_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}
