import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import holonextlogo from "../media/holonextlogo.png";

import { IonSpinner } from "@ionic/react";

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  background: rgba(255, 255, 255, 0.5);
`;

const LoadingPart = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LoadingImage = styled.img`
  width: 60%;
`;

const Loading = ({
  sceneIsFetching,
  userIsFetching,
  viewLoading,
  dashboardIsFetching,
  show
}) => {
  // console.log({ sceneIsFetching, userIsFetching, viewLoading,dashboardIsFetching });
  if (
    sceneIsFetching ||
    userIsFetching ||
    viewLoading ||
    dashboardIsFetching ||
    show
  ) {
    return (
      <>
        <Container></Container>
        <LoadingPart>
          <LoadingImage src={holonextlogo} />
          <IonSpinner name="dots" />
        </LoadingPart>
      </>
    );
  }
  return <></>;
};

const mapState = (state, ownProps) => ({
  sceneIsFetching: state.scenes.isFetching,
  userIsFetching: state.user.isFetching,
  viewLoading: state.view.loading,
  dashboardIsFetching: state.dashboard.isFetching
});

export default connect(mapState, {})(Loading);
