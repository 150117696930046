function getFileNameWithoutExtension(fileNameOrPath) {
	let extensionStartIndex = fileNameOrPath.lastIndexOf('.');
	return fileNameOrPath.slice(0, extensionStartIndex);
}

// returns the extension in file string with dot, eg. .zip, .png etc
function getFileExtension(fileNameOrPath) {
	let extensionStartIndex = fileNameOrPath.lastIndexOf('.');
	return fileNameOrPath.slice(extensionStartIndex);
}

function getFileName({ path }) {
	let index = path.lastIndexOf('/');
	return path.slice(index + 1);
}

function hasFile({ extension, scene }) {
	return scene.files.some((f) => getFileExtension(f.fileName) === extension);
}

function hasFileOfFormats({ extensions, scene }) {
	return extensions.some((extension) => hasFile({ extension, scene }));
}

function hasUsdz(scene) {
	return hasFile({ extension: '.usdz', scene });
}

function getFileOfFormat({ extension, scene }) {
	return scene.files.find((f) => getFileExtension(f.fileName) === extension);
}

function getUsdzFile(scene) {
	return getFileOfFormat({ scene, extension: '.usdz' });
}

function haveScenesAnyGLTFFile(scenes, gltfExtensions = ['.gltf', '.glb']) {
	return scenes.some((scene) =>
		gltfExtensions.some((extension) => hasFile({ scene, extension }))
	);
}

function formatBytes(a, b = 2) {
	if (0 === a) return '0 Bytes';
	const c = 0 > b ? 0 : b,
		d = Math.floor(Math.log(a) / Math.log(1024));
	return (
		parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
		' ' +
		['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
	);
}

export {
	getFileNameWithoutExtension,
	getFileExtension,
	getFileName,
	hasFile,
	hasFileOfFormats,
	hasUsdz,
	getFileOfFormat,
	getUsdzFile,
	haveScenesAnyGLTFFile,
	formatBytes,
};
