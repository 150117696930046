import { combineReducers } from "redux";

import scenes from "./scenes/reducers";
import user from "./user/reducers";
import view from "./view/reducers";
import dashboard from "./dashboard/reducers";
import rooms from "./rooms/reducers";
const rootReducer = combineReducers({
  scenes,
  user,
  rooms,
  view,
  dashboard
});

export default rootReducer;
